import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Col, Row, Table } from 'react-bootstrap';
import { HSRItem } from '../../../modules/hsr/common/components/hsr-item';
import { HSRLightCone } from '../../../modules/hsr/common/components/hsr-light-cone';

const HSRGuidesNamelessPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Nameless Honor</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_nameless.png"
            alt="Nameless Honor"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Nameless Honor</h1>
          <h2>The Nameless Honor system from Honkai: Star Rail explained.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Nameless Honor" />
        <StaticImage
          src="../../../images/starrail/generic/guide_nameless_1.webp"
          alt="Guide"
        />
        <p>
          Nameless Honor is the name for the <strong>Battle Pass</strong> in
          Honkai: Star Rail. You unlock it at Trailblazer Level 15 (your account
          level).
        </p>
        <p>
          It works similarly to Battle Passes you might know from other gacha
          games - for completing missions you are given Nameless Honor EXP that
          unlocks new levels, and to each level there tied are various rewards.
        </p>
        <p>Here's a summary of the system:</p>
        <ul>
          <li>Each season of the Nameless Honor lasts for 45 days,</li>
          <li>There are 50 levels in total,</li>
          <li>The Nameless Honor is split into:</li>
          <ul>
            <li>
              Nameless Gift - the free path of the Battle Pass and those rewards
              are given to everyone who unlock them,
            </li>
            <li>Nameless Glory - the paid path with additional rewards,</li>
          </ul>
          <li>
            The price to unlock the Nameless Glory is currently set at $9.99,
          </li>
          <li>
            The missions you can do are split into Daily Missions, Weekly
            Missions and Period Missions,
          </li>
          <ul>
            <li>
              While Daily and Weekly Missions are self-explanatory, the Period
              Missions last for the duration of the whole Battle Pass.
            </li>
          </ul>
          <li>
            If you're in a rush, you can buy a level of Nameless Honor for 150
            Stellar Jades.
          </li>
          <li>
            The main reward of the Battle Pass is a Light Cone selector that
            allows you to pick one of seven 4★ Light Cones.
          </li>
        </ul>
        <SectionHeader title="List of rewards" />
        <p>
          You can find all the rewards available in both paths of the Nameless
          Honor in the table below.
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Level</th>
              <th>Nameless Gift (free)</th>
              <th>Nameless Glory (paid)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="adventure-log" mode="inline" enablePopover />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="condensed-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    15000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    60000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="adventure-log" mode="inline" enablePopover />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="fuel" mode="inline" enablePopover /> x 2
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="fuel" mode="inline" enablePopover /> x 2
                  </li>
                  <li>
                    <HSRItem slug="lost-crystal" mode="inline" enablePopover />{' '}
                    x 10
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="condensed-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    15000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    60000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="adventure-log" mode="inline" enablePopover />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="condensed-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-special-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                  <li>
                    <HSRItem slug="lost-crystal" mode="inline" enablePopover />{' '}
                    x 10
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="adventure-log" mode="inline" enablePopover />{' '}
                    x 8
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 8
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="condensed-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 8
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    20000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    80000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="adventure-log" mode="inline" enablePopover />{' '}
                    x 8
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 8
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>15</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="tracks-of-destiny"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="tracks-of-destiny"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 2
                  </li>
                  <li>
                    <HSRItem
                      slug="gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>16</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="condensed-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 8
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>17</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    20000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    80000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>18</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="adventure-log" mode="inline" enablePopover />{' '}
                    x 8
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 8
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>19</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="condensed-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 8
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>20</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-special-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                  <li>
                    <HSRItem
                      slug="gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>21</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 3
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 12
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>22</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 3
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 12
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>23</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    30000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    120000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>24</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 3
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 12
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>25</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="fuel" mode="inline" enablePopover /> x 2
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="fuel" mode="inline" enablePopover /> x 2
                  </li>
                  <li>
                    <HSRItem
                      slug="gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>26</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 3
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 12
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>27</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    30000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    120000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>28</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 16
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>29</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 16
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>30</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 2
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-special-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 2
                  </li>
                  <li>
                    <HSRItem
                      slug="treasure-of-the-starry-seas"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>31</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 16
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>32</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 4
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 16
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>33</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    40000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    160000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>34</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 5
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 20
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>35</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="tracks-of-destiny"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="tracks-of-destiny"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 2
                  </li>
                  <li>
                    <HSRItem
                      slug="gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>36</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 5
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 20
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>37</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    40000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    160000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>38</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 5
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 20
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>39</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 5
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 20
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>40</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="self-modeling-resin"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="relic-remains" mode="inline" enablePopover />{' '}
                    x 200
                  </li>
                  <li>
                    <HSRItem
                      slug="grand-gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>41</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 24
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>42</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 24
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>43</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    50000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    200000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>44</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 24
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>45</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="fuel" mode="inline" enablePopover /> x 2
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="fuel" mode="inline" enablePopover /> x 2
                  </li>
                  <li>
                    <HSRItem
                      slug="grand-gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>46</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 24
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>47</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    50000
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="credit" mode="inline" enablePopover /> x
                    200000
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>48</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="travelers-guide"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 24
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>49</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 6
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="refined-aether"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 24
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem
                      slug="star-rail-pass"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
              <td>
                <ul className="hsr-item-list">
                  <li>
                    <HSRItem slug="stellar-jade" mode="inline" enablePopover />{' '}
                    x 680
                  </li>
                  <li>
                    <HSRItem
                      slug="grand-gift-of-the-pathstriders"
                      mode="inline"
                      enablePopover
                    />{' '}
                    x 1
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Light Cones" />
        <p>
          Here's a list of the Light Cones you can obtain via the Nameless Honor
          (inside the Treasure of the Starry Seas).
        </p>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <HSRLightCone mode="card" slug="carve-the-moon-weave-the-clouds" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="nowhere-to-run" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="return-to-darkness" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="this-is-me" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="today-is-another-peaceful-day" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="warm-nights-wont-last" />
          </Col>
          <Col>
            <HSRLightCone mode="card" slug="we-will-meet-again" />
          </Col>
        </Row>
        <div className="cones-container"></div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesNamelessPage;

export const Head: React.FC = () => (
  <Seo
    title="Nameless Honor | Honkai: Star Rail | Prydwen Institute"
    description="The Nameless Honor system from Honkai: Star Rail explained."
  />
);
